body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mainBackground {
  background-color: #43314A;
}

.rtl {
  direction: rtl;
}
.calcualtorStyle {background-color:#e5e6e3;border:1px solid #000}

.custom-table td,
.custom-table th {
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 8px
}
.custom-tabel-header {
  background-color: #f1f1f1;
  font-weight: 700
}

.responsive-container {
  max-width: 100%;
  padding: 15px;
  margin: auto;
}